import React from 'react';
import classes from './dropzone.module.css';
import { File } from 'react-feather';

function Dropzone(props) {
    const {
        inputProps,
        innerDragProps,
        isDragActive,
        dropzoneRef,
        isFixed = false
    } = props;

    return (
        <div>
            {isDragActive ? (
                <div
                    {...innerDragProps}
                    className={`
                    ${classes.outerWrapper}
                    ${isFixed ? classes.fixed : null}`}
                    ref={dropzoneRef}
                >
                    <input {...inputProps} />
                    <div className={classes.innerWrapper}>
                        <div
                            data-testid="dropZone"
                            className={classes.dropzone}
                        >
                            <File />
                            <p className={classes.instructions}>
                                Drag and drop your exported AutoQuotes project
                                here!
                                <br />
                                Alternately, click here to select an export from
                                a folder
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default Dropzone;
