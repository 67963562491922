import ProjectListControls from './ProjectListControls';

export const headers = [
    {
        label: 'Name',
        value: 'name',
        sortable: false
    },
    {
        label: 'Customer #',
        value: 'customerNo',
        sortable: false
    },
    {
        label: 'Job Reference #',
        value: 'jobRef',
        sortable: false
    },
    {
        label: '',
        value: 'icons',
        searchable: false,
        sortable: false,
        component: ProjectListControls
    }
];
