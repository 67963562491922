import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import classes from './itemImage.module.css';

function ItemImage({ row }) {
    const [error, setError] = useState(true);
    const [objectUrl, setObjectUrl] = useState('');
    const { itemNumberFull } = row;
    const thumbnailUrl =
        'https://d2ayqyulezm17z.cloudfront.net/thumbnails/' +
        encodeURIComponent(itemNumberFull) +
        '.jpg';

    useEffect(() => {
        if (itemNumberFull != null && itemNumberFull !== '') {
            fetch(thumbnailUrl).then(async response => {
                if (response.ok) {
                    setError(false);
                    const myBlob = await response.blob();
                    setObjectUrl(URL.createObjectURL(myBlob));
                } else {
                    setError(true);
                }
            });
        }
    }, [itemNumberFull, thumbnailUrl]);

    return error ? null : (
        <img className={classes.root} alt="Item" src={objectUrl} />
    );
}

ItemImage.propTypes = {
    hello: string
};

export default ItemImage;
