import React, { useCallback } from 'react';
import { File } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import handleDrop from 'src/util/handleDrop';
import { Button } from 'fronds/Controllers';

function ImportButton() {
    const onDropCallBack = useCallback(handleDrop, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onDropCallBack
    });

    const { onClick } = getRootProps();

    const inputProps = getInputProps();

    return (
        <Button onClick={onClick}>
            <File />
            Import project
            <input {...inputProps} />
        </Button>
    );
}

export default ImportButton;
