import React from 'react';
import { Button } from 'fronds/Controllers';
import actions from 'src/store/actions/project';
import { useDispatch } from 'react-redux';

function ToggleLineButton({ row, index }) {
    const isDisabled = !row.MainItem && row.Inactive;

    const dispatch = useDispatch();
    return (
        <Button
            disabled={isDisabled}
            data-testid="toggleActive"
            onClick={() => dispatch(actions.toggleActive(index))}
        >
            {row.Inactive ? 'Add' : 'Remove'}
        </Button>
    );
}

export { ToggleLineButton };
