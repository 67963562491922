import React, { useState, useEffect } from 'react';
import classes from './projectSummary.module.css';
import { EditField } from 'fronds/Controllers';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions/project';
import getFacilities from 'src/util/getFacilities';

function ProjectSummary(props) {
    const dispatch = useDispatch();
    const { project, remainingLineCount, navigator } = props;
    const { customerNo, jobRef, name, selectedFacility } = project.projectHead;
    const temporaryOrders = project.temporaryOrders;
    let s = remainingLineCount === 1 ? '' : 's';
    let [facilities, setFacilities] = useState([]);
    useEffect(() => {
        async function getData() {
            let facilities = await getFacilities();
            setFacilities(facilities);
        }
        getData();
    }, []);

    let TempOrderNoRow = temporaryOrders
        ? temporaryOrders.map(tempOrder => (
              <div
                  data-testid="projectSummary"
                  key={tempOrder.temporaryOrderNumber}
                  className={classes.displayRowWrapper}
              >
                  <DisplayRow label="Name: " value={tempOrder.name} />
                  <DisplayRow
                      label="Batch Order #:"
                      value={tempOrder.temporaryOrderNumber}
                  />
              </div>
          ))
        : null;

    function updateCustomerNo(value) {
        dispatch(
            actions.setProjectHead({
                ...project.projectHead,
                customerNo: value
            })
        );
    }

    function updateJobRef(value) {
        dispatch(
            actions.setProjectHead({ ...project.projectHead, jobRef: value })
        );
    }

    function updateFacility(value) {
        dispatch(actions.setFacility(value));
    }

    return (
        <div className={classes.root}>
            <h5> {name} </h5>
            <div className={classes.header}>
                <div className={classes.counter}>{remainingLineCount}</div>
                <div className={classes.counterLabel}>unmatched item{s}</div>
                <div className={classes.navigator}>
                    {navigator ? navigator : null}
                </div>
            </div>
            <div className={classes.meta}>
                <DisplayRow
                    label="Customer #:"
                    value={
                        <EditField
                            text={customerNo}
                            onSave={updateCustomerNo}
                        />
                    }
                />
                <DisplayRow
                    label="Job Ref:"
                    value={<EditField text={jobRef} onSave={updateJobRef} />}
                />
            </div>
            <select
                defaultValue={selectedFacility}
                value={selectedFacility}
                onChange={ev => updateFacility(ev.target.value)}
            >
                {facilities.map(facility => (
                    <option key={facility}> {facility} </option>
                ))}
            </select>

            <div className={classes.orders}>{TempOrderNoRow}</div>
        </div>
    );
}

function DisplayRow(props) {
    const { label, value } = props;

    return (
        <div className={classes.displayRow}>
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>{value}</div>
        </div>
    );
}

export default ProjectSummary;
