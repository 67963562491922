export function sanitizeInput(input) {
    if (typeof input === 'number') {
        return input;
    } else if (typeof input === 'string') {
        input = input.replace(/[^0-9.-]/g, '');
        const parsed = parseFloat(input);
        return parsed ? parsed : 0;
    } else {
        return 0;
    }
}

export function currencyFormat(number) {
    if (number == null) {
        return '--';
    }
    const value = sanitizeInput(number);
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}

export function currencyNumber(number) {
    if (number == null) {
        return 0;
    }
    const value = sanitizeInput(number);
    const output = parseFloat(value.toFixed(2));
    return output;
}
