import React from 'react';
import { useDispatch } from 'react-redux';
import projectActions from 'src/store/actions/project';
import { EditField } from 'fronds/Controllers';
import { currencyNumber, currencyFormat } from 'src/util/numberFormats';

function PriceField({ row, index }) {
    const dispatch = useDispatch();

    function save(value) {
        const SellPrice = currencyNumber(value);
        dispatch(projectActions.updateData({ ...row, index, SellPrice }));
    }

    return <EditField text={currencyFormat(row.SellPrice)} onSave={save} />;
}

export default PriceField;
