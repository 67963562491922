import React from 'react';
import projectActions from './project';
import appActions from './app';
import requestHeaders from '../../util/requestHeaders';
import store from '../../store';
import request from '../../util/requests/request';
import { currencyNumber } from '../../util/numberFormats';

export function getVendors(payload) {
    const vendors = payload.reduce((accum, vendor) => {
        let vendorProps = {
            name: vendor.Name
        };
        accum[vendor.VendorId] = vendorProps;
        return accum;
    }, {});
    return vendors;
}

export async function addDataAndRequestItemNumber(data, vendors) {
    // Match model # to what exists in Infor
    let count = 0;
    const promises = data.LineItems.reduce((accum, item) => {
        item.SubLineItems[0].MainItem = true;
        // Sales prices need to be rounded for M3 to accept them
        item.SellPrice = currencyNumber(item.SellPrice);
        const promises = item.SubLineItems.map((item, index) => {
            item.SellPrice = currencyNumber(item.SellPrice);
            if (item.VendorId !== null) {
                item.VendorName = vendors[item.VendorId].name;
                item.LineNo = count;
            }
            count += 1;
            return new Promise(async resolve => {
                resolveItem(item, resolve);
            });
        });
        return accum.concat(promises);
    }, []);
    return await Promise.all(promises);
}

async function resolveItem(item, resolve) {
    const endpoint = process.env.API_URL + 'api/Supplier/';
    try {
        const response = await fetch(`${endpoint}${item.Model}`, {
            headers: {
                Authorization:
                    requestHeaders.prefix + requestHeaders.getIdToken(),
                'Content-Type': 'application/json'
            }
        });
        const matchedItems = await response.json();
        resolve({
            ...item,
            Inactive: item.StatusDescription === 'Inactive',
            matchedItems: matchedItems.data,
            itemNumberFull:
                matchedItems.data.length > 0
                    ? matchedItems.data[0].itemNumber_ITNO
                    : null
        });
    } catch (error) {
        console.warn(error);
        resolve({
            ...item,
            ...item.Main,
            Inactive: item.StatusDescription === 'Inactive',
            matchedItems: [],
            itemNumberFull: null
        });
    }
}

export function importXML(json) {
    return async function(dispatch) {
        try {
            dispatch(appActions.setLoading());
            const data = JSON.parse(json);

            const project = {};
            project.projectHead = {
                customerNo: data.Code,
                jobRef: data.JobRef,
                name: data.ProjectName,
                selectedFacility: 'TEX' // Default to Texas
            };
            project.vendors = getVendors(data.Vendors);
            const dataWithItemNumber = await addDataAndRequestItemNumber(
                data,
                project.vendors
            );
            project.data = dataWithItemNumber;
            dispatch(projectActions.addProject(project));
            dispatch(appActions.setFinishedLoading());
        } catch (error) {
            dispatch(
                appActions.addErrorMessage(
                    'There was a problem importing the XML. If this happens again please submit a bug report.'
                )
            );
            dispatch(appActions.setFinishedLoading());
            throw error;
        }
    };
}

export function submitBatchOrder(orders) {
    return async function(dispatch) {
        try {
            dispatch(appActions.setLoading());
            const { projects, selectedProject } = store.getState().project;
            const { projectHead } = projects[selectedProject];
            const customerNo = projectHead ? projectHead.customerNo : '';
            const jobRef = projectHead ? projectHead.jobRef : '';
            const facility = projectHead ? projectHead.selectedFacility : '';

            const batchOrderRequests = Object.entries(orders).map(
                async ([name, value]) => {
                    await createBatchOrder(
                        value,
                        customerNo,
                        jobRef,
                        name,
                        facility
                    );
                }
            );
            await Promise.all(batchOrderRequests);
            dispatch(appActions.setFinishedLoading());
        } catch (error) {
            dispatch(
                appActions.addErrorMessage(
                    'A problem occurred while transferring this job to Infor. If this happens again please submit a bug report.'
                )
            );
            dispatch(appActions.setFinishedLoading());
            throw error;
        }
    };
}

async function createBatchOrder(data, customerNo, jobRef, name, facility) {
    try {
        const endpoint = process.env.API_URL + 'api/CustomerOrder/';
        const Items = data.filter(item => !item.Inactive);
        const postBody = {
            body: {
                CustomerNo: customerNo,
                Items,
                JobRef: jobRef,
                Facility: facility
            }
        };
        const response = await request(`${endpoint}`, postBody);
        const temp = response.temporaryOrderNumber;
        if (temp)
            store.dispatch(
                projectActions.setTempOrders({
                    temporaryOrderNumber: temp,
                    orderData: data,
                    name
                })
            );
        store.dispatch(
            appActions.addSuccessMessage(
                <span>
                    <b>{name}</b>: Order for Customer #<b>{customerNo}</b>{' '}
                    created successfully
                </span>
            )
        );
    } catch (e) {
        throw e;
    }
}
