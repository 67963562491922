import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './App.module.css';
import Home from 'src/rootComponents/Home';
import { gapiInit } from 'src/util/gapiUtil';
import requestHeaders from 'src/util/requestHeaders';
import { Login, Message } from 'fronds/Components';
import states from 'src/constants/appState';
import actions from 'src/store/actions/app';
import { useDispatch } from 'react-redux';
import quotes from 'src/constants/wizardQuotes.json';
import { Overlay, LoadingRipple } from 'fronds/Components';

function QuoteOverlay() {
    const loading = useSelector(state => {
        return state.app.loading;
    });

    if (!loading) return null;
    let { quote, quotee } = quotes[
        Math.floor(Math.random() * (quotes.length - 1))
    ];
    quote = quote.replace(/{{.*}}/, 'Wizard Quotes');
    return (
        <Overlay>
            <div className={classes.wizardQuote}>
                <div className={classes.header}>
                    Just a moment...
                    <br />
                    The Wizard is working its magic
                </div>
                <div className={classes.quote}>"{quote}"</div>
                <div className={classes.quotee}>-{quotee}</div>
                <div className={classes.rippleWrapper}>
                    <LoadingRipple />
                </div>
            </div>
        </Overlay>
    );
}

function App() {
    const [appState, setAppState] = useState(states.loading);
    const { projects, selectedProject } = useSelector(state => {
        return state.project;
    });
    const dispatch = useDispatch();

    const messages = useSelector(state => {
        return state.app.messages;
    });

    function signIn() {
        gapiInit()
            .then(idToken => {
                requestHeaders.setIdToken(idToken);
                setAppState(states.loggedIn);
            })
            .catch(error => {
                setAppState(states.signedOut);
                console.error(error);
            });
    }

    const idToken = requestHeaders.getIdToken();
    if (idToken === '') {
        signIn();
    }

    if (appState === 'loading') {
        return <p> Loading... </p>;
    }

    return (
        <>
            {appState === 'loggedIn' ? (
                <div className={classes.root}>
                    <header className={classes.title}>
                        <div className={classes.titleContent}>
                            <div className={classes.logo}>
                                <img src="logo192.png" alt="app logo" />
                            </div>
                            <h1 className={classes.titleText}>Wizard Quotes</h1>
                            <div className={classes.subTitle}>
                                The AutoQuotes Wizard
                            </div>
                            <div className={classes.version}>
                                ver. {process.env.REACT_APP_GIT_SHA}
                            </div>
                        </div>
                    </header>
                    <Home
                        projects={projects}
                        selectedProject={selectedProject}
                    />
                </div>
            ) : (
                <Login
                    onSignin={async () => {
                        let auth2 = window.gapi.auth2.getAuthInstance();

                        try {
                            await auth2.signIn();
                            signIn();
                        } catch (err) {
                            // eslint-disable-next-line no-console
                            console.warn(err);
                        }
                    }}
                >
                    {' '}
                    Please sign in{' '}
                </Login>
            )}
            <div className={classes.message}>
                <Message
                    dismissTime={8}
                    onDismiss={() => dispatch(actions.removeMessage())}
                    onDismissAll={() => dispatch(actions.clearAllMessages())}
                    messages={messages}
                />
            </div>
            <QuoteOverlay />
        </>
    );
}

export default App;
