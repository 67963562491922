export function gapiInit() {
    return new Promise((resolve, reject) => {
        try {
            window.gapi.load('auth2', async () => {
                const auth2 = await window.gapi.auth2.init({
                    client_id:
                        '1019325154513-g0fbl65i0bn92k0kramc4tonomjilu4q.apps.googleusercontent.com',
                    scope:
                        'email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
                });
                const isSignedIn = auth2.isSignedIn.get();
                if (isSignedIn) {
                    const idToken = auth2.currentUser.get().getAuthResponse()
                        .id_token;
                    resolve(idToken);
                } else {
                    reject({
                        error: 'Not signed in'
                    });
                }
            });
        } catch (err) {
            reject(err);
        }
    });
}

export async function refreshAuthToken() {
    let auth2 = window.gapi.auth2.getAuthInstance();
    const authResponse = await auth2.currentUser.get().reloadAuthResponse();
    return authResponse.id_token;
}
