import fetchIntercept from 'fetch-intercept';
import requestHeaders from 'src/util/requestHeaders';
import { refreshAuthToken } from 'src/util/gapiUtil';
import { promiseWithStatus, decodeJWT } from 'fronds/Util';

let tokenUpdating = promiseWithStatus(new Promise(resolve => resolve()));

async function handleTokenInHeader(config) {
    const {
        payload: { exp }
    } = decodeJWT(config.headers.Authorization);
    let expire = Math.floor(exp - Date.now() / 1000);
    if (expire < 0) {
        config = await handleExpiredToken(config);
        return config;
    } else {
        return config;
    }
}

async function handleExpiredToken(config) {
    if (tokenUpdating.isFulfilled() && !tokenUpdating.isPending()) {
        reauthorizeToken();
        const idToken = await waitForTokenAndUpdateConfig(config);
        requestHeaders.setIdToken(idToken);
        return config;
    } else if (tokenUpdating) {
        await waitForTokenAndUpdateConfig(config);
        return config;
    }
}

async function waitForTokenAndUpdateConfig(config) {
    const idToken = await tokenUpdating;
    config.headers.Authorization = `Bearer ${idToken}`;
    return idToken;
}

function reauthorizeToken() {
    tokenUpdating = promiseWithStatus(
        new Promise(async resolve => {
            const newToken = await refreshAuthToken();
            resolve(newToken);
        })
    );
}

const defaultConfig = {
    headers: {}
};

export async function requestInterceptor(url, config) {
    try {
        config = { ...defaultConfig, ...config };
        config.headers.Authorization =
            requestHeaders.prefix + requestHeaders.getIdToken();
        if (config.headers.Authorization) {
            config = await handleTokenInHeader(config);
            return [url, config];
        } else {
            return [url, config];
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}

fetchIntercept.register({
    request: requestInterceptor,

    requestError: function(error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function(response) {
        // Modify the reponse object
        return response;
    },

    responseError: function(error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});
