import React from 'react';
import classes from './projectListControls.module.css';
import { Button } from 'fronds/Controllers';
import { number } from 'prop-types';
import { useDispatch } from 'react-redux';
import projectActions from 'src/store/actions/project';

function ProjectListControls({ index }) {
    const dispatch = useDispatch();
    return (
        <div className={classes.root}>
            <Button
                data-testid="openProject"
                onClick={() => {
                    dispatch(projectActions.setSelectedProject(index));
                }}
            >
                Open
            </Button>
            <Button
                data-testid="deleteProject"
                onClick={() => {
                    dispatch(projectActions.deleteProject(index));
                }}
            >
                Delete
            </Button>
        </div>
    );
}

ProjectListControls.propTypes = {
    index: number
};

export default ProjectListControls;
