import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, MiniTabs } from 'fronds/Components';
import { useSelector } from 'react-redux';
import { headers, previewHeaders } from './headers';
import classes from './itemCheck.module.css';
import ChevronRight from 'feather/chevron-right';
import ChevronLeft from 'feather/chevron-left';
import projectActions from 'src/store/actions/project';
import ProjectSummary from 'src/components/ProjectSummary';
import Database from 'feather/database';
import { submitBatchOrder } from 'src/store/actions/projectAsyncActions';
import { Button } from 'fronds/Controllers';
import store from 'src/store';

const possibleMethods = {
    submitBatchOrder: 'submitBatchOrder',
    submitBatchOrderSeparateVendorNonStock:
        'submitBatchOrderSeparateVendorNonStock'
};

const orderMethods = {
    submitBatchOrder: () => {
        const { projects, selectedProject } = store.getState().project;
        const { data } = projects[selectedProject];
        return { Order: data.filter(row => !row.Inactive) };
    },
    submitBatchOrderSeparateVendorNonStock: () => {
        const { projects, selectedProject } = store.getState().project;
        const { data } = projects[selectedProject];
        const mappedVendorData = data.reduce((accum, LineItem) => {
            if (!LineItem.Inactive) {
                if (LineItem.StockItem === false) {
                    // TODO: Put vendor in LineItem?
                    const vendorName = LineItem.VendorName;
                    if (accum[vendorName]) {
                        accum[vendorName].push(LineItem);
                    } else {
                        accum[vendorName] = [LineItem];
                    }
                } else {
                    if (accum['Stock Items']) {
                        accum['Stock Items'].push(LineItem);
                    } else {
                        accum['Stock Items'] = [LineItem];
                    }
                }
            }
            return accum;
        }, {});
        return mappedVendorData;
    }
};

function ItemCheck(props) {
    const { project } = props;
    const dispatch = useDispatch();
    const [orders, setOrders] = useState(false);
    const [showInactive, setShowInactive] = useState(false);
    const [submitMethod, setSubmitMethod] = useState(
        possibleMethods.submitBatchOrder
    );
    let lineHighlight = useSelector(state => state.project.lineHighlight);
    const [focusLineIndex, setFocusLineIndex] = useState(
        lineHighlight ? lineHighlight : 0
    );
    const data = project.data;
    let rowProps = {};

    let incompleteLines = data
        ? data.filter(item => {
              return !item.itemNumberFull && !item.Inactive;
          })
        : [];

    let subLineItems = data
        ? data.filter(item => {
              return !item.MainItem;
          })
        : [];

    let hiddenLines = data
        ? data.filter(item => {
              return item.Inactive !== showInactive;
          })
        : [];
    let incompleteLineCount = incompleteLines ? incompleteLines.length : 0;
    let isAllMatched = incompleteLineCount === 0;

    incompleteLines.forEach(row => {
        rowProps[row.LineNo] = { ref: React.createRef() };
    });

    subLineItems.forEach(row => {
        rowProps[row.LineNo] = {
            ...rowProps[row.LineNo],
            className: classes.subLineItem
        };
    });

    hiddenLines.forEach(row => {
        rowProps[row.LineNo] = {
            ...rowProps[row.LineNo],
            className: classes.hidden
        };
    });

    let isHighlight = lineHighlight && rowProps[lineHighlight];

    if (isHighlight) {
        const currentClassName = rowProps[lineHighlight].className;
        const newClass = currentClassName
            ? currentClassName + ' ' + classes.selectedRow
            : classes.selectedRow;
        rowProps[
            lineHighlight
        ].className = `${newClass} ${rowProps[lineHighlight].className}`;
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    function submitOrder() {
        if (isAllMatched && !orders) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            setOrders(orderMethods[submitMethod]());
        } else {
            dispatch(submitBatchOrder(orders));
        }
    }

    let className =
        classes.createOrderButton +
        (!isAllMatched ? ' ' + classes.inactive : '');
    let title = !isAllMatched
        ? 'All lines must be matched with a valid item number in M3'
        : null;

    function SidebarItem(props) {
        const { className, value } = props;
        return (
            <div className={className} {...props}>
                {value}
            </div>
        );
    }

    function highlightTargetLine(index) {
        if (incompleteLines.length < 1) return;
        if (index < 0) index = incompleteLines.length - 1;
        const validatedIndex = isHighlight ? index % incompleteLines.length : 0;
        const targetLine = incompleteLines[validatedIndex].LineNo;
        if (rowProps[targetLine].ref.current.scrollIntoView) {
            rowProps[targetLine].ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
        setFocusLineIndex(validatedIndex);
        dispatch(projectActions.setLineHighlight(targetLine));
    }

    const navigator = (
        <>
            <button onClick={() => highlightTargetLine(focusLineIndex - 1)}>
                <ChevronLeft />
            </button>
            <button
                onClick={() => highlightTargetLine(focusLineIndex + 1)}
                data-testid="nextUnmatchedItem"
            >
                <ChevronRight />
            </button>
        </>
    );

    return (
        <div className={classes.root}>
            <div className={classes.metaTile}>
                <ProjectSummary
                    remainingLineCount={incompleteLineCount}
                    project={project}
                    navigator={navigator}
                />
            </div>
            <Button
                data-testid="backButton"
                onClick={() => {
                    orders
                        ? setOrders(false)
                        : dispatch(projectActions.setSelectedProject(null));
                }}
            >
                {orders ? 'Back to edit order' : 'Back to project list'}
            </Button>
            <div className={!orders ? classes.hidden : null}>
                <OrderPreview data={orders} />
            </div>
            <div className={orders ? classes.hidden : null}>
                <MiniTabs
                    title=""
                    selectedItem={showInactive ? 1 : 0}
                    ItemComponent={SidebarItem}
                    className={classes.tabs}
                    items={[
                        {
                            'data-testid': 'activeTab',
                            id: 0,
                            value: 'Active List Items',
                            onClick: () => {
                                setShowInactive(false);
                            }
                        },
                        {
                            'data-testid': 'inactiveTab',
                            id: 1,
                            value: 'Inactive List Items',
                            onClick: () => setShowInactive(true)
                        }
                    ]}
                />
                <Table rowProps={rowProps} headers={headers} rows={data} />
            </div>
            <div className={className}>
                <select
                    data-testid="submitOrderSelector"
                    onChange={ev => setSubmitMethod(ev.target.value)}
                >
                    <option
                        data-testid="optionSubmitOrder"
                        value={possibleMethods.submitBatchOrder}
                    >
                        Add all items to one order
                    </option>
                    <option
                        data-testid="optionSubmitOrderSeparateVendor"
                        value={
                            possibleMethods.submitBatchOrderSeparateVendorNonStock
                        }
                    >
                        Create an order per vendor
                    </option>
                </select>
                <button
                    className={classes.submitButton}
                    data-testid="submitOrder"
                    onClick={submitOrder}
                    title={title}
                >
                    <Database size={18} />
                    <div>{!orders ? 'Preview' : 'Submit'}</div>
                </button>
                <button
                    className={classes.cancelButton}
                    data-testid="cancelorder"
                    onClick={() => setOrders(false)}
                    title={title}
                    disabled={!orders}
                >
                    <div className={'test'}>Cancel</div>
                </button>
            </div>
        </div>
    );
}

function OrderPreview(props) {
    const { data } = props;
    const tables = Object.entries(data).map(([key, value]) => {
        return (
            <div
                className={classes.preview}
                data-testid="OrderPreview"
                key={key}
            >
                <h1>{key}</h1>
                <Table headers={previewHeaders} rows={value} />
            </div>
        );
    });

    return tables;
}

export default ItemCheck;
