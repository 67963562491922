import React from 'react';
import classes from './editItem.module.css';
import { DropdownSelect } from 'fronds/Controllers';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions/project';

function MatchedItems(props) {
    const { matchedItems, row, index } = props;
    const defaultValue = {
        label: row.itemNumberFull,
        value: row.itemNumberFull
    };

    const dispatch = useDispatch();

    return matchedItems.length > 1 ? (
        <DropdownSelect
            isSearchable={false}
            defaultValue={defaultValue}
            isMulti={false}
            isClearable={false}
            options={matchedItems.map(item => ({
                label: item.itemNumber,
                value: item.itemNumber
            }))}
            onSelectionChange={({ value }) => {
                dispatch(
                    actions.updateData({ ...row, index, itemNumberFull: value })
                );
            }}
        />
    ) : (
        <div>{row.itemNumberFull}</div>
    );
}

export default function EditItem({ row, index }) {
    return (
        <div className={classes.root}>
            {row.matchedItems.length > 0 ? (
                <MatchedItems
                    row={row}
                    matchedItems={row.matchedItems.reduce(
                        (accum, matchedItem) => {
                            let exists = accum.find(
                                item =>
                                    item.itemNumber === matchedItem.itemNumber
                            );
                            if (!exists) {
                                accum.push(matchedItem);
                            }
                            return accum;
                        },
                        []
                    )}
                    index={index}
                />
            ) : row.itemNumberFull ? (
                row.itemNumberFull
            ) : null}
        </div>
    );
}
