import store from 'src/store';
import { importXML } from 'src/store/actions/projectAsyncActions';
import appActions from 'src/store/actions/app';

export default function handleDrop(acceptedFiles) {
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
        // Do whatever you want with the file contents
        try {
            const file = reader.result;
            store.dispatch(importXML(file));
        } catch (e) {
            console.warn(e);
        }
    };
    acceptedFiles.forEach(file => {
        if (!(file.type === '' || file.type === 'application/json')) {
            store.dispatch(
                appActions.addErrorMessage(
                    'Can only handle files exported using the "JSON Export" option in Autoquotes'
                )
            );
            console.error(
                'File has incorrect extension, this is potentially not fatal. Proceeding to read anyways...'
            );
        }
        reader.readAsBinaryString(file);
    });
}
