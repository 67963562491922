import React, { useState, useEffect, useRef } from 'react';
import useRequest from 'src/util/requests/useRequest';
import { Spinner } from 'fronds/Components';
import classes from './fieldSearch.module.css';
import ItemSearch from 'src/components/ItemSearch';
import { useDispatch } from 'react-redux';
import projectActions from 'src/store/actions/project';
import AlertCircle from 'feather/alert-circle';
import { Button } from 'fronds/Controllers';

const endpoint = process.env.API_URL + 'api/Item/';

export default function({ row, index }) {
    let [loadingState, searchItem, controller] = useRequest();
    const [isSearchShowing, setIsSearchShowing] = useState(false);
    const [response, setResponse] = useState([]);
    const { Model, itemNumberFull, matchedItems } = row;
    const dispatch = useDispatch();

    function findExactMatch(values, Model) {
        return values.find(value => {
            const model = itemNumberFull || Model;
            return value.itemNumber === model;
        });
    }

    useEffect(() => {
        if (matchedItems.length === 0) {
            searchItem(
                `${endpoint}${encodeURIComponent(itemNumberFull || Model)}`
            )
                .then(response => {
                    const values = response;
                    if (!response.Error && values.length > 0) {
                        setResponse(values);
                    } else {
                        setResponse([]);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        } //eslint-disable-next-line
    }, [Model, row]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    });

    let statusIcon =
        loadingState.loading || itemNumberFull ? (
            <Spinner {...loadingState} />
        ) : (
            <div
                className={
                    response.length === 0
                        ? classes.noMatchAlert
                        : classes.matchAlert
                }
            >
                <AlertCircle />
            </div>
        );

    let es = response.length === 1 ? '' : 'es';
    function MatchResult() {
        const matchResult = findExactMatch(response, Model)
            ? {
                  class: 'success',
                  text: 'Exact match'
              }
            : {
                  class: 'info',
                  text: 'Partial Match'
              };
        return (
            <span className={classes[matchResult.class]}>
                {matchResult.text}
            </span>
        );
    }

    return (
        <>
            {matchedItems.length === 0 ? (
                <div className={classes.root}>
                    {statusIcon}
                    <div className={classes.matchDescription}>
                        <span className={classes.match}>
                            {response.length} Match{es}
                        </span>
                        {response.length > 0 ? <MatchResult /> : null}
                    </div>
                    <Button
                        data-testid="toggleSearchButton"
                        onClick={() =>
                            setIsSearchShowing(isShowing => !isShowing)
                        }
                    >
                        {isSearchShowing ? 'Close search' : 'Search'}
                    </Button>
                    <div className={classes.searchContainer}>
                        {isSearchShowing ? (
                            <ItemSearchContainer
                                setIsShowing={setIsSearchShowing}
                                itemNumber={itemNumberFull || Model}
                                row={row}
                                index={index}
                            />
                        ) : null}
                    </div>
                </div>
            ) : (
                <div className={classes.root}>
                    <Spinner {...loadingState} />
                    <div className={classes.matchDescription}>
                        <p> Model # exists in INFOR </p>
                    </div>
                    <Button
                        onClick={() => {
                            dispatch(projectActions.clearMatchedItems(index));
                            setIsSearchShowing(isShowing => !isShowing);
                        }}
                    >
                        Search
                    </Button>
                </div>
            )}
        </>
    );
}

function ItemSearchContainer(props) {
    const { setIsShowing, itemNumber, row, index } = props;
    const searchContainerRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        function handleMouseClick(ev) {
            if (searchContainerRef.current) {
                if (!searchContainerRef.current.contains(ev.target)) {
                    setIsShowing(false);
                }
            }
        }
        document.addEventListener('click', handleMouseClick);
        return () => {
            document.removeEventListener('click', handleMouseClick);
        };
    }, [setIsShowing]);

    function handleResult(newRow) {
        dispatch(
            projectActions.updateData({
                ...row,
                itemNumberFull: newRow.itemNumber,
                index
            })
        );
        setIsShowing(false);
    }

    return (
        <div className={classes.searchWrapper} ref={searchContainerRef}>
            <ItemSearch itemNumber={itemNumber} handleResult={handleResult} />
        </div>
    );
}
