export default {
    5: 'Template item',
    10: 'Prel. item',
    15: 'Replace item',
    20: 'Released',
    30: 'Alternate item',
    40: 'Lw turnovr',
    50: 'Discontinued',
    80: 'Not carrd',
    90: 'Not stock',
    99: 'Itm no chg'
};
