import { useState, useRef } from 'react';

const defaultState = {
    loading: false,
    error: false
};

const defaultPostOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
};

function useRequest() {
    const controllerRef = useRef(new AbortController());
    const [state, setState] = useState(defaultState);

    async function tryRequest(url, options = {}) {
        const body = options.body ? JSON.stringify({ ...options.body }) : null;
        options = body ? { ...options, ...defaultPostOptions, body } : options;
        setState({
            ...defaultState,
            loading: true
        });
        controllerRef.current = new AbortController();
        try {
            return await request(url, options);
        } catch (error) {
            setState({
                ...defaultState,
                error: true
            });
            console.warn(error);
            throw error;
        }
    }

    async function request(url, options) {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers
            },
            signal: controllerRef.current.signal
        });
        const json = await response.json();
        if (response.ok) {
            setState({
                ...defaultState,
                loading: false
            });
        } else {
            setState({
                ...defaultState,
                error: true
            });
            throw response.statusText;
        }
        return json;
    }

    return [state, tryRequest, controllerRef.current];
}

export default useRequest;
