import actions from '../actions/app';
import { handleActions } from 'redux-actions';

export const projectInitialState = {
    messages: [],
    loading: false
};

const reducerMap = {
    [actions.addErrorMessage]: (state, action) => {
        const newMessages = state.messages.slice();
        newMessages.push({
            type: 'ERROR',
            message: action.payload
        });
        return { ...state, messages: newMessages };
    },
    [actions.addSuccessMessage]: (state, action) => {
        const newMessages = state.messages.slice();
        newMessages.push({
            type: 'SUCCESS',
            message: action.payload
        });
        return { ...state, messages: newMessages };
    },
    [actions.addInfoMessage]: (state, action) => {
        const newMessages = state.messages.slice();
        newMessages.push({
            type: 'INFO',
            message: action.payload
        });
        return { ...state, messages: newMessages };
    },
    [actions.removeMessage]: state => {
        const newMessages = state.messages.slice();
        newMessages.pop();
        return { ...state, messages: newMessages };
    },
    [actions.clearAllMessages]: state => {
        return { ...state, messages: [] };
    },
    [actions.setLoading]: state => {
        return { ...state, loading: true };
    },
    [actions.setFinishedLoading]: state => {
        return { ...state, loading: false };
    }
};

export default handleActions(reducerMap, projectInitialState);
