const defaultPostOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
};

async function tryRequest(url, options = {}) {
    const body = options.body ? JSON.stringify({ ...options.body }) : null;
    options = body ? { ...options, ...defaultPostOptions, body } : options;
    try {
        return await request(url, options);
    } catch (error) {
        console.warn(error);
        throw error;
    }
}

async function request(url, options) {
    const response = await fetch(url, {
        ...options,
        headers: {
            ...options.headers
        }
    });
    const json = await response.json();

    if (!response.ok) {
        throw response.statusText;
    }

    return json;
}

export default tryRequest;
