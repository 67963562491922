import React, { useEffect, useState } from 'react';
import { Table, Util, Spinner } from 'fronds/Components';
import headers from './headers';
import classes from './itemSearch.module.css';
import propTypes from 'prop-types';
import useRequest from 'src/util/requests/useRequest';
import { Input, Button } from 'fronds/Controllers';

const { useDebounce } = Util;

const endpoint = process.env.API_URL + 'api/Item/';

export default function ItemSearch(props) {
    const { handleResult } = props;
    const [itemNumber, setItemNumber] = useState(props.itemNumber);
    const [response, setResponse] = useState(null);
    const [debouncedItemNumber] = useDebounce(itemNumber, 200);
    const [loadingState, searchItem, controller] = useRequest();

    useEffect(() => {
        if (debouncedItemNumber) {
            search();
        } //eslint-disable-next-line
    }, [debouncedItemNumber]);

    function updateForm(ev) {
        controller.abort();
        const value = ev.currentTarget.value;
        setItemNumber(value);
    }

    function search() {
        searchItem(`${endpoint}${encodeURIComponent(debouncedItemNumber)}`)
            .then(value => {
                let mapped = value.map(itemRes => ({
                    itemNumber: itemRes.itemNumber_ITNO,
                    status: itemRes.status_STAT,
                    name: itemRes.name_ITDS
                }));
                setResponse(mapped);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const headerProps = {
        handleSort: () => {},
        initialSort: {},
        handleColumnSearch: () => {},
        initialColumnSearch: {}
    };

    return (
        <div data-testid="itemSearch" className={classes.root}>
            <form
                className={classes.form}
                onSubmit={event => {
                    event.preventDefault();
                }}
            >
                <Input
                    autoFocus
                    onChange={updateForm}
                    defaultValue={props.itemNumber}
                />
                <span>
                    <Button
                        onClick={() => {
                            controller.abort();
                            search();
                        }}
                    >
                        {' '}
                        Search{' '}
                    </Button>
                    <Spinner {...loadingState} />
                </span>
            </form>
            <div>
                {response instanceof Array ? (
                    <Table
                        headers={headers}
                        rows={response}
                        headerProps={headerProps}
                        onClick={handleResult}
                        hideHeaders={true}
                    />
                ) : null}
            </div>
        </div>
    );
}

ItemSearch.propTypes = {
    itemNumber: propTypes.string
};
