import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { log } from 'fronds/ReduxMiddleware';
import projectReducer from 'src/store/reducers/project';
import appReducer from 'src/store/reducers/app';
import { save, load } from 'redux-localstorage-simple';

const REDUX_SCHEMA_VERSION = '3.0';

const rootReducer = combineReducers({
    project: projectReducer,
    app: appReducer
});

const middleWare = [thunk];

const modesToAddMiddleware = ['development'];

if (modesToAddMiddleware.includes(process.env.NODE_ENV)) {
    middleWare.push(log);
}

const savedStates = ['project'];

const createStoreWithMiddleware = applyMiddleware(
    ...middleWare,
    save({
        namespace: REDUX_SCHEMA_VERSION,
        states: savedStates
    }) // Saving done here
)(createStore);

export const createNewDebugStore = () => createStore(rootReducer);

export const createNewStore = () =>
    createStoreWithMiddleware(
        rootReducer,
        load({
            states: savedStates,
            namespace: REDUX_SCHEMA_VERSION,
            disableWarnings: true
        }) // Loading done here
    );

const store = createNewStore();

export default store;
