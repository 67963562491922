let idToken = '';

export default {
    getIdToken: () => {
        return idToken;
    },
    setIdToken: newIdToken => {
        idToken = newIdToken;
    },
    prefix: 'Bearer '
};
