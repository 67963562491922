import FieldSearch from 'src/components/FieldSearch';
import EditItem from 'src/components/EditItem';
import ItemImage from './ItemImage';
import { ToggleLineButton } from 'src/components/ToggleLineButton';
import PriceField from 'src/components/PriceField';

import React from 'react';
import classes from './itemCheck.module.css';
import { ReadMore } from 'fronds/Components';

function StockItemComponent({ row }) {
    return row.StockItem === false ? 'Non-Stock' : 'Stock';
}

export const headers = [
    {
        label: '',
        component: ItemImage,
        searchable: false,
        value: 'ItemImage',
        sortable: false
    },
    {
        label: 'Status',
        value: 'icons',
        component: FieldSearch,
        searchable: false,
        sortable: false
    },
    {
        label: 'Matched Item#',
        component: EditItem,
        value: 'EditItem',
        sortable: false,
        class: 'alignLeft'
    },
    {
        label: 'Original Item#',
        value: 'Model',
        sortable: false,
        class: 'alignLeft'
    },
    {
        label: 'Vendor',
        value: 'VendorName',
        sortable: false
    },
    {
        label: 'Specification',
        value: 'Spec',
        sortable: false,
        component: ({ row }) => {
            return (
                <div className={classes.specWrapper}>
                    <ReadMore startHeight={'80px'}>
                        <p className={classes.spec}> {row.Spec} </p>
                    </ReadMore>
                </div>
            );
        }
    },
    {
        label: 'Stock Item',
        value: 'StockItem',
        sortable: false,
        component: StockItemComponent
    },
    {
        label: 'Sell Price',
        value: 'SellPrice',
        sortable: false,
        component: PriceField
    },
    {
        value: 'ToggleLineButton',
        label: '',
        component: ToggleLineButton,
        sortable: false
    }
];

export const previewHeaders = [
    {
        label: '',
        component: ItemImage,
        searchable: false,
        value: 'ItemImage',
        sortable: false
    },
    {
        label: 'Matched Item#',
        value: 'itemNumberFull',
        sortable: false,
        class: 'alignLeft'
    },
    {
        label: 'Original Item#',
        value: 'Model',
        sortable: false,
        class: 'alignLeft'
    },
    {
        label: 'Vendor',
        value: 'VendorName',
        sortable: false
    },
    {
        label: 'Specification',
        value: 'Spec',
        sortable: false
    },
    {
        label: 'Stock',
        value: 'Stock_Item',
        component: StockItemComponent,
        sortable: false
    }
];
