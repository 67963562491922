import itemStatusCodes from 'src/constants/itemStatusCodes';

export default [
    {
        value: 'itemNumber',
        label: 'Item Number',
        class: 'alignLeft'
    },
    {
        value: 'name',
        label: 'Name'
    },
    {
        value: 'status',
        label: 'Status',
        format: status => itemStatusCodes[status]
    }
];
