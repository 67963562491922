import { createActions } from 'redux-actions';

const prefix = 'APP';

const actionTypes = [
    'ADD_ERROR_MESSAGE',
    'ADD_SUCCESS_MESSAGE',
    'ADD_INFO_MESSAGE',
    'REMOVE_MESSAGE',
    'CLEAR_ALL_MESSAGES',
    'SET_LOADING',
    'SET_FINISHED_LOADING'
];

export default createActions(...actionTypes, { prefix });
