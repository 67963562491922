import React from 'react';
import { string } from 'prop-types';
import { Table } from 'fronds/Components';
import { headers } from './headers';
import ImportButton from 'src/components/ImportButton';

function ProjectList(props) {
    const { projects, fallback } = props;
    const rows = projects.map(project => {
        return {
            ...project.projectHead
        };
    });
    return (
        <>
            <ImportButton />
            <Table fallback={fallback} headers={headers} rows={rows} />
        </>
    );
}

ProjectList.propTypes = {
    hello: string
};

export default ProjectList;
