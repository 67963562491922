import { createActions } from 'redux-actions';

const prefix = 'PROJECT';

const actionTypes = [
    'UPDATE_DATA',
    'SET_LINE_COUNT',
    'SET_TEMP_ORDERS',
    'ADD_PROJECT',
    'SET_SELECTED_PROJECT',
    'DELETE_PROJECT',
    'TOGGLE_ACTIVE',
    'SET_LINE_HIGHLIGHT',
    'CLEAR_MATCHED_ITEMS',
    'SET_PROJECT_HEAD',
    'SET_FACILITY'
];

export default createActions(...actionTypes, { prefix });
