import actions from '../actions/project';
import { handleActions } from 'redux-actions';

export const projectInitialState = {
    projects: [],
    selectedProject: null,
    lineHighlight: null
};

const reducerMap = {
    [actions.updateData]: (state, action) => {
        if (action.payload.index === undefined) return { ...state };
        const projects = state.projects.slice();
        const project = { ...projects[state.selectedProject] };
        const updateKey = action.payload.index;
        const newData = project.data.slice();
        newData.splice(updateKey, 1, action.payload);
        projects[state.selectedProject].data = newData;
        return { ...state, projects };
    },
    [actions.setTempOrders]: (state, action) => {
        const projects = state.projects.slice();
        const project = { ...projects[state.selectedProject] };
        const newTemporaryOrders = project.temporaryOrders.slice();
        newTemporaryOrders.push(action.payload);
        project.temporaryOrders = newTemporaryOrders;
        projects[state.selectedProject] = project;
        return { ...state, projects };
    },
    [actions.addProject]: (state, action) => {
        const selectedProject = state.projects.length;
        const projects = state.projects.slice();
        action.payload.temporaryOrders = [];
        projects.push(action.payload);
        return { ...state, selectedProject, projects };
    },
    [actions.setSelectedProject]: (state, action) => {
        const selectedProject = action.payload;
        return { ...state, selectedProject };
    },
    [actions.deleteProject]: (state, action) => {
        const projects = state.projects.slice();
        const updateKey = action.payload;
        projects.splice(updateKey, 1);
        return { ...state, projects };
    },
    [actions.toggleActive]: (state, action) => {
        const { projects, selectedProject } = state;
        const newData = projects[selectedProject].data.slice();
        // Retrieve the index of the user-selected row
        const primaryIndex = action.payload;
        // If it's an inactive subitem whose main item is also inactive, do nothing
        // TODO: Display a message if the user attempts this
        if (!newData[primaryIndex].MainItem && newData[primaryIndex].Inactive) {
            let i = primaryIndex - 1;
            while (newData[i] && !newData[i].MainItem) i--;
            if (newData[i].Inactive) return { ...state };
        }
        // Iterate to find any associated subitems
        const rowsToToggle = [primaryIndex];
        if (newData[primaryIndex].MainItem) {
            let i = primaryIndex + 1;
            while (newData[i] && !newData[i].MainItem) {
                if (newData[i].Inactive === newData[primaryIndex].Inactive) {
                    rowsToToggle.push(i);
                }
                i++;
            }
        }
        rowsToToggle.forEach(i => (newData[i].Inactive = !newData[i].Inactive));
        projects[selectedProject].data = newData;
        return { ...state, projects };
    },
    [actions.clearMatchedItems]: (state, action) => {
        const { projects, selectedProject } = state;
        const newData = projects[selectedProject].data.slice();
        const row = action.payload;
        newData[row].matchedItems = [];
        newData[row].itemNumberFull = null;
        projects[selectedProject].data = newData;
        return { ...state, projects };
    },
    [actions.setLineHighlight]: (state, action) => {
        return { ...state, lineHighlight: action.payload };
    },
    [actions.setProjectHead]: (state, action) => {
        const projects = state.projects.slice();
        const project = { ...projects[state.selectedProject] };
        project.projectHead = action.payload;
        projects[state.selectedProject] = project;
        return { ...state, projects };
    },
    [actions.setFacility]: (state, action) => {
        const projects = state.projects.slice();
        const project = { ...projects[state.selectedProject] };
        project.projectHead.selectedFacility = action.payload;
        projects[state.selectedProject] = project;
        return { ...state, projects };
    }
};

export default handleActions(reducerMap, projectInitialState);
