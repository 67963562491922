import React, { useCallback, useRef } from 'react';
import Dropzone from 'src/components/Dropzone';
import ItemCheck from 'src/rootComponents/ItemCheck';
import { useDropzone } from 'react-dropzone';
import classes from './home.module.css';
import handleDrop from 'src/util/handleDrop';
import ProjectList from 'src/rootComponents/ProjectList';

export default function Home(props) {
    const { projects, selectedProject } = props;
    const dropzoneRef = useRef(null);
    const onDropCallBack = useCallback(handleDrop, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropCallBack
    });

    const {
        onBlur,
        onClick,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
        onFocus,
        onKeyDown
    } = getRootProps();

    const isProjectSelected =
        selectedProject != null && projects[selectedProject].data;

    const inputProps = getInputProps();

    const fallBackDropZone = (
        <Dropzone
            inputProps={inputProps}
            innerDragProps={{
                onBlur,
                onClick,
                onFocus,
                onKeyDown
            }}
            isDragActive={isDragActive || projects.length === 0}
            dropzoneRef={dropzoneRef}
        />
    );

    return (
        <div
            className={classes.root}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={ev => {
                // Close the drop overlay when the mouse leaves the window
                if (
                    ev.target.className ===
                    (dropzoneRef.current && dropzoneRef.current.className)
                ) {
                    onDragLeave(ev);
                }
            }}
        >
            <Dropzone
                inputProps={getInputProps()}
                isFixed={true}
                innerDragProps={{
                    onBlur,
                    onClick,
                    onFocus,
                    onKeyDown
                }}
                isDragActive={isDragActive && projects.length > 0}
                dropzoneRef={dropzoneRef}
            />
            <div className={classes.content}>
                {isProjectSelected ? (
                    <ItemCheck project={projects[selectedProject]} />
                ) : (
                    <ProjectList
                        fallback={fallBackDropZone}
                        projects={projects}
                    />
                )}
            </div>
        </div>
    );
}
